
.homeTitle {
    margin-top: 4%;
    margin-bottom: 20px;
}

.card-body {
    color: dimgray;
    border-radius: 4px;
    cursor: pointer;

}

svg {

    margin-left: 15px;
}

.card .card-body :hover {
    color: cornflowerblue;

}

.card:hover {
    color: cornflowerblue;
    border-color: cornflowerblue;
    box-shadow: 0 2px 2px 2px darkgrey;
}


h4 {
    margin-left: 12px;
}



.navbar-light .navbar-nav .nav-link {
    margin-left: 8px;
    font-size: 16px;


}

.navbar :hover{

    color : #79c90f !important;

}

.selected{

    color : #79c90f !important;
    font-weight: 500;


}


