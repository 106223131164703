

.invoiceInfo{
    width: 95%;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 6%;
    border-radius: 4px;
    font-size: 16px;
    line-height: 2;
}

.invoiceInfo:hover .invoiceBtn{

    display: block;
}


.invoiceInfo h6{
    margin-top: 2%;
    border-radius: 4px;
}


.sensusImg{
    margin-left: 35%;
    padding-top: 20px;
    height: 150px;
}
.invoiceBtn{

    display: none;
}

.sensusImg img {
    height: 30px;

    width: 95px;
}
