.btn {
    margin-top: 20px;
}

.top {
    margin-left: 1%;

    width: 48%;

}

.btn-outline-success {

    margin-left: 45%;
}


.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
}

.Range .DayPicker-Day {
    border-radius: 0 !important;
}

.DayPicker {
    width: 100%;
    height: 100%;
}

.Range .DayPicker-Day--start {
    border-top-left-radius: 0% !important;
    border-bottom-left-radius: 0% !important;
}

.Range .DayPicker-Day--end {
    border-top-right-radius: 0% !important;
    border-bottom-right-radius: 0% !important;
}

.DayPicker-Month {


    width: 46%;
}

.schedulerForm {
    margin-top: 12%;
}

.schedulerRange {

    margin-top: 3%;

}


.myIcon {

    margin-left: 0px;
    margin-top: 4px;
}

.bs-stepper-label {
    display: inline-block;
    margin: .20rem;
    font-size: 12px;
}

.bs-stepper-circle {

    display: inline-flex;
    align-content: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    padding: .5em 0;
    margin: .25rem;
    line-height: 1em;
    color: #fff;
    background-color: #6c757d;
    border-radius: 3em;
}

.stageInput {
    margin-top: 20px;
    width: 45%;
}

.laserInput{

    margin-top: 20px;
margin-left: 6%;
}

.dateRangeStages {

    margin-top: 20%;
}

.bs-stepper-header {

    margin-top: 4%;

}

.laserTitle{

    margin-top: 25px;
    font-size: 25px;
    color:#a3c2c2;
    font-weight: bold;
    margin-left: 1%;

}

.laserText{
    margin-left: 1%;
    margin-top: 5px;
    font-size: 20px;
    color: #6c757d;
    font-family: inherit;
}


.bs-stepper-content {

    padding: 0px;
}

.laserBookingImg {

    margin-top: 70px;
    height: 315px;

}

.laserBookingImg img {
    height: 315px;
    width: 170px;

}

.style_lds-roller__19Wqb {
    margin-left: 50%;

    margin-top: 100px;
    margin-bottom: 129px;

}


.laserBookingImg .style_lds-roller__19Wqb{


    margin-top: 100px;
    margin-left: 2%;

}
.stepperBtn {

    margin-top: 60px;
    margin-left: 5%
}

.stepperBtnClinic {
    margin-top: 20px;
    margin-left: 17%;
}

.stepperBtnFirst {
    margin-top: 40px;

    margin-left: 35%;
}


.stepperBtnFirst .btn {

    width: 50%;
}

.stepperBtnClinic .btn {
    margin-left: 2%;
    width: 35%;
}

.stepperBtn .btn {

    margin-left: 2%;
    width: 35%;
}

.btn svg {

    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 3px;
}

.bookingDetails {

    padding-top: 15px;
    padding-left: 5px;
    margin-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 23px;
    width: 40%;
    margin-left: 30%;
    box-shadow: 0 2px 2px 2px darkgrey;

}

.bookingDetailsTitle{

text-align: center;
    font-size: 25px;
    margin-bottom: 20%;
}

.bookingDetailsTitle svg{

    margin-left: 4px;

    margin-bottom: 10px;
}

.cardText{

    margin-top: 35px;
}

.subTitle {
    margin-right: 5px;
    font-weight: bold;
}






.bookingCreated{

    margin-top: 25px;
    color: forestgreen;
    font-size: 20px;
}

.bookingUpdated{

    margin-top: 23px;
    color: orange;
    font-size: 17px;
}


.bookingCreated svg{

    color: forestgreen;

}

.bookingCreatedBtn{

    margin-left: 25%;
}

.bookingCreatedBtn .btn{

    margin-left: 35px;
}

.updateSubmitBtn{

    margin-left: 28%;

}

.updateSubmitBtn .btn{

    margin-left: 35px;

}


