.dropDown{

    margin-right: 20px;

}

.dropDown a{

    color: rgba(0,0,0,.5);

}

.dropDown{

    color: rgba(0,0,0,.5);

}

.dropdown-menu.show{

    width: 190px;
}


