table{
font-size: 14px;
    margin-top: 30px;
    margin-bottom: 30%;
    margin-left: 3%;
}

.table-striped tbody tr:nth-of-type(odd):hover{

    background: #ffffe6;
}

.table-striped tbody tr:nth-of-type(even):hover{

    background: #ffffe6;
}

.bookingTable{
    margin-top: 5%;
}

.lastLogin{

    margin-top: 5%;
    margin-left: 2%;
}

