
.modalContent{

    padding-left: 6px;
}

.modalBtn{

    margin-top: 60px;
    margin-left: 78%;
}