
.statisticsContainer{
padding-left: 10%;
    max-width: 90%;
}
.statisticsChart{

    height: 250px;
}

.statisticsTitle{

    margin-top: 20px;
}

.statisticsSubTitle{

    margin-top: 25px;
padding-left: 10px;
}