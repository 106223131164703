
.header {
    text-align: center;
}

.custom-btn-toolbar {
    display: inline !important;
}

.custom-btn-toolbar > .btn {
    margin-right: 10rem;
}

@media screen and (max-width: 800px) {
    .rcw-widget-container {
        margin: 0;
        max-height: 50%;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .rcw-conversation-container .rcw-close-button {
        background-color: #00b300;
        border: 0;
        display: block;
        position: absolute;
        right: 10px;
        top: 20px;
        width: 40px;
    }
}

.rcw-client .rcw-message-text {
    background-color: #ccffcc;
    border-radius: 10px;
    padding: 15px;
    max-width: 215px;
    text-align: left;
}

.rcw-conversation-container > .rcw-header {

    background-color: #00b300;

}


.rcw-launcher {

    background-color: #00b300;
}

.logoImg {

    margin-left: 25%
}

.logoImg img {
    height: 80px;
    width: 270px;
    margin-top: 40px;
}


.loginForm {

    border-radius: 5px;
    border-style: solid;
    border-width: 0.5px;
    border-color: lightgrey;
    padding: 30px;
    font-weight: 400;
    line-height: 2;
    text-align: left;
    margin-top: 70px;
}

.loginForm .title{

    font-weight: bold;
    font-size: 23px;
    text-align: center;
    margin-bottom: 25px;
}

.changeStateMessageLabel {

    font-size: 14px;
    margin-top: 10px;

}


.changeStateMessageLabel span{

    font-size: 14px;
margin-top: 10px;
    margin-left: 10px;

}

.changeStateMessage{

    font-size: 14px;
    cursor: pointer;
    color: #79c90f;
}

.changeStateMessage:hover{

    color: #1c7430;
    text-decoration: underline;
}

.PhoneInputInput {

    padding-left: 10px;
    flex: 0;
    height: 44px;
    min-width: 0;
    border-radius: 5px;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}





