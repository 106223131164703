
.activityTitle {
    margin-top: 30px;
    margin-left: 10px;

}

.item {

    margin-top: 2%;
}

.cardWrapper:hover .bookingBtns .btn {

    width: 42%;
    margin-left: 2%;
    display: inline;
}

.cardWrapper:hover .lasersBtns .btn {
    width: 100%;

    display: block;
}

.bookingBtns svg {

    margin-left: 5px;
}

.lasersBtns svg {

    margin-left: 5px;
}

.cardInfo {
    width: 85%;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 6%;
    border-radius: 4px;
    font-size: 16px;
    line-height: 2;


}

.bookingStatus {
    margin-left: 70%;
}

.bookingDelivered {

    color: green;
}

.bookingInProcess {

    color: orange;
}


.bookingPending {

    color: grey;
}

.cardInfo h6 {
    margin-top: 2%;
    border-radius: 4px;

}


.laserImg {
    padding-top: 10px;
    height: 220px;
    padding-left: 4%;

}

.itemData {
    padding-left: 10px;
}

.laserImg img {
    height: 185px;

    width: 110px;
}

@media screen and (max-width: 800px) {
    .cardInfo {
        width: 60%;

        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {
    .laserImg {
        width: 35%;
        height: 170px;


    }
}

@media screen and (max-width: 800px) {
    .laserImg img {

        padding-top: 10px;

        height: 140px;
        width: 80px;


    }
}

.laserType {

    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    .laserType {
        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {

    .bookingStatus {
        margin-top: 10px;
        margin-left: 42%;
    }
}

.spinner {
    margin-left: 46%;
    margin-top: 20%;
    size: 100px;
}

.spinnerSubmit{

    margin-top: 20px;
    margin-left: 30%;


}

.smallFont{

    font-size: 15px;
}

.bookingList {

    margin-bottom: 30px;
}

.bookingBtns {
    margin-top: 106px;
}

.lasersBtns {

}

.mLeft {

    margin-left: 10px;
}

.bookingBtns .btn {
    display: none;

    width: 100%;
}

.lasersBtns .btn {
    width: 100%;
    display: none;

}
