.map {

    height: 40vh;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px;
}

.clinicsMap {
    margin-top: 10px;

    height: 65vh;
    width: 100%;
    border-radius: 20px;
}

.gm-control-active > img {

    display: none;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mapMarker {

    width: 27px;
    height: 43px;
    overflow: hidden;
    position: absolute;
    left: -14px;
    top: -43px;
    z-index: 0;
}

.imgMarker {

    position: absolute;
    left: 0px;
    top: 0px;
    width: 27px;
    height: 43px;
    user-select: none;
    border: 0px;
    padding: 0px;
    margin: 0px;
    max-width: none;
}

.clinicMarker {

    width: 16px;
    height: 25px;
    overflow: hidden;
    position: absolute;
    left: -14px;
    top: -43px;
    z-index: 0;
}

.iconClinic {

    position: absolute;
    left: 0px;
    top: 0px;
    width: 16px;
    height: 25px;
    user-select: none;
    border: 0px;
    padding: 0px;
    margin: 0px;
    max-width: none;
}


.routeInfo {

    margin-top: 10px;
    font-size: 17px;
    margin-bottom: 20px;
}

.distanceInfo{

    margin-left: 10px;
}

.bold{

    font-weight: 650;

}
