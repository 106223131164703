

.operationTitle {
    display: inline;
}


.operationsTop {
    margin-left: 2%;
    width: 98%;
    margin-top: 25px;
}

@media screen and (max-width: 800px) {
    .operationsTop {

        margin-top: 15px;

    }
}


.operationTitle h3 {
    display: inline;

}

@media screen and (max-width: 800px) {
    .operationTitle h3 {

        margin-left: 10px;
        font-size: 23px;
    }
}

.operationsTopButtonsFilters {
    margin-left: 15%;
}

@media screen and (max-width: 800px) {
    .operationsTopButtonsFilters {
        margin-left: 10%;
        display: block;

    }
}

.filterButton {
    margin-left: 1%;
    width: 17%;
}

@media screen and (max-width: 800px) {
    .filterButton {
        margin-left: 2%;
width: 42%;
    }
}

.DayPickerInput {
    margin-top: 15px;
    margin-left: 1%;
    width: 51%;

}

@media screen and (max-width: 800px) {
    .DayPickerInput {
        margin-left: 4%;
        width: 46%;
    }
}

.DayPickerInput input {
    width: 100%;
    text-align: center;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.operationsTopButtonsFilters .col {
    display: inline;
}

.operationsFilters {
    display: inline;
}

@media screen and (max-width: 800px) {
    .filterClinic {
        margin-left: 4%;
        width: 40%;
    }
}

.filterClinic {
    margin-top: 15px;
    margin-left: 1%;
    display: inline;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    width: 47%;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.minInfo{

    font-size: 12px;
}