.clinicInfo{
    padding-top: 10px;
    padding-left: 20px;
    height: 105px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2px;

}

.clinicInfo:hover .clinicBtn{

    display: inline;

}

.clinicAddress{
    padding-top: 7px;
    font-size: 12px;
    font-weight: 400;

}

.clinicList{
    padding-bottom: 2px;
    height: 65vh;
    overflow-y: scroll;
}


.clinicBtn{
    display: none;
    margin-top: 2px;
}

.clinicBtn .btn{
    margin-top: 15px;
    width: 44%;
}

.leftSpace{

    margin-left: 4%;
}

.locationInput{
    display: inline;

}



.searchBtn {

    margin-top: 12px;
}

.clinicAddressInput{
    margin-top: 20px;

}

.imgInput{
    margin-top: 20px;

}

.myImg{
margin-top: 30px;
    max-height: 320px;

}