.loginError{
    margin-top: 15px;
    color: #dc3545;

}

.fieldInvalid{
    color: #dc3545;

    font-size: 80%;
}

.formLabel{

    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.pointer{

    cursor: pointer;
}
