.lasersTop.row {
    margin-top: 25px;
    margin-bottom: 30px;
}




.custom-switch  .custom-control-label::before {
    color: #fff;
    border-color: green;
    background-color: green;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem;
    border-color: #fff;
    background-color: #fff;
}

.btn {
    margin-top: 20px;
}

.statusLabel{
margin-right: 15px;
    margin-left: 15px;
}

.laserStatus{
    margin-left: 70%;
}

.btn-outline-success {
    width: 99%;
    margin-left: 1%;
}

.createLaserTypeBtn {
    width: 99%;
}

.btn .btn-outline-success svg{

    margin-left: 20px;
}


.myIcon {

    margin-left: 0px;
    margin-top: 4px;
}

.bs-stepper-label {
    display: inline-block;
    margin: .20rem;
    font-size: 12px;
}

.bs-stepper-circle {

    display: inline-flex;
    align-content: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    padding: .5em 0;
    margin: .25rem;
    line-height: 1em;
    color: #fff;
    background-color: #6c757d;
    border-radius: 3em;
}

.stageInput {
    margin-top: 20px;
    width: 45%;
}

.dateRangeStages {

    margin-top: 20%;
}

.bs-stepper-header {

    margin-top: 4%;

}


.bs-stepper-content {

    padding: 0px;
}

.style_lds-roller__19Wqb {
    margin-left: 50%;
    margin-bottom: 129px;

}

.imgRoller{

    margin-top: 100px;
}
.laserBookingImg .style_lds-roller__19Wqb{

    margin-left: 2%;

}

.laserInput{

    margin-top: 20px;

}

.stepperBtn {

    margin-top: 60px;
    margin-left: 5%
}

.stepperBtnClinic {
    margin-top: 40px;
    margin-left: 17%;
}

.stepperBtnFirst {
    margin-top: 40px;

    margin-left: 35%;
}


.stepperBtnFirst .btn {

    width: 50%;
}

.stepperBtnClinic .btn {
    margin-left: 2%;
    width: 35%;
}

.stepperBtn .btn {

    margin-left: 2%;
    width: 35%;
}

.btn svg {

    margin-left: 6px;
    margin-bottom: 4px;
}

.bookingDetails {
    padding-top: 15px;
    padding-left: 5px;
    margin-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 23px;
    width: 40%;
    margin-left: 30%;
    box-shadow: 0 2px 2px 2px darkgrey;

}

.bookingDetailsTitle{

    text-align: center;
    font-size: 25px;
    margin-bottom: 20%;
}

.bookingDetailsTitle svg{

    margin-left: 4px;

    margin-bottom: 10px;
}

.cardText{

    margin-top: 35px;
}

.subTitle {
    margin-right: 5px;
    font-weight: bold;
}

.stepperBtnSubmit .btn{
    width: 90%;
}



.stepperBtnSubmit svg{

    margin-left: 5px;
}

.stepperBtnSubmit .style_lds-roller__19Wqb {

    margin-top: 30px;
    margin-left: 1%;

}






